import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import { loadReCaptcha } from 'react-recaptcha-google';
import Moment from 'react-moment';
import 'moment-timezone';

import store from "./store";

import './App.css';

import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Terms from "./components/auth/Terms";
import Plans from "./components/auth/Plans";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Deployments from "./components/dashboard/Deployments";
import Confirmation from "./components/auth/Confirmation";
import Rejection from "./components/auth/Rejection";
import Approval from "./components/auth/Approval";
import Success from "./components/auth/Success";
import Created from "./components/dashboard/Created";

import login_export_1 from "./assets/login_export_1.svg";
import login_export_2 from "./assets/login_export_2.svg";
import login_export_3 from "./assets/login_export_3.svg";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

const images = [
  login_export_1,
  login_export_2,
  login_export_3
];
const imagesPath = '';

class Random extends React.Component {
  getRandomImage() {
    var path = imagesPath;
    var num = Math.floor(Math.random() * images.length);
    var img = images[num];
    return path + img;
  }
  render() {
    return (
      <div className="center" >
        <img src={this.getRandomImage()} alt="login" />
      </div>
    );
  }
}

class AuthViews extends React.Component {
  render() {
    return (
      <div className="row tellius-container valign-wrapper" style={{ height: "95vh" }}>
        <div className="col s12 m5 l5 center">
          <Random />
        </div>
        <div className="col s0 m2 l2">
        </div>
        <div className="col s12 m5 l5 valign">
          <Route exact path="/signup" component={Register} />
          <Route exact path="/login" component={Login} />
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
          </Switch>
        </div>
      </div>
    );
  }
}

class TermsViews extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "50px" }} className="">
        <Route exact path="/terms" component={Terms} />
      </div>
    );
  }
}

class TelliusPlans extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "50px" }} className="">
        <Route exact path="/tellius-plans" component={Plans} />
      </div>
    );
  }
}

export default class App extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <div className="container">
              <Route exact path="/" component={Landing} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/confirmation/:token" component={Confirmation} />
              <Route exact path="/rejection/:token" component={Rejection} />
              <Route exact path="/approval/:token" component={Approval} />
              <div className="valign-wrapper">
                <Route exact path="/(signup|login|dashboard)" component={AuthViews} />
              </div>
              <Route exact path="/(deployments)" component={Deployments} />
              <Route exact path="/terms" component={TermsViews} />
              <Route exact path="/tellius-plans" component={TelliusPlans} />
              <Route exact path="/created" component={Created} />
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}
