import {
    SET_CURRENT_USER,
    USER_LOADING,
    SET_REGISTRATION_SUCCESS,
    SET_VERIFICATION_SUCCESS,
    SET_APPROVAL_SUCCESS,
    SET_REJECTION_SUCCESS,
    SET_RESEND_SUCCESS
} from "../actions/types";
const isEmpty = require("is-empty");

const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    registrationSuccess: false,
    registrationMessage: null,
    registrationPayload: {},
    verificationPayload: {},
    approvalPayload: {},
    rejectionPayload: {},
    resendPayload: {}
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case SET_REGISTRATION_SUCCESS:
            return {
                ...state,
                registrationSuccess: action.payload.success,
                registrationMessage: action.payload.message,
                registrationPayload: action.payload
            };
        case SET_VERIFICATION_SUCCESS:
            return {
                ...state,
                verificationPayload: action.payload
            };
        case SET_APPROVAL_SUCCESS:
            return {
                ...state,
                approvalPayload: action.payload
            };
        case SET_REJECTION_SUCCESS:
            return {
                ...state,
                rejectionPayload: action.payload
            };
        case SET_RESEND_SUCCESS:
            return {
                ...state,
                resendPayload: action.payload
            };
        default:
            return state;
    }
}