import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser, checkUserValidity } from "../../actions/authActions";
import { setCompanyName, createDeployment } from "../../actions/dashboardActions";
import classnames from "classnames";


function TerminatedText(props) {
    return (
        <div className="heading-desc row">
            <div className="col s12 m12 l12">
                Your instance has been terminated. If this was done in error, please reach out to <strong>product@tellius.com</strong>
            </div>
        </div>
    );
}

function ExpiredText(props) {
    return (
        <div className="heading-desc row">
            <div className="col s12 m12 l12">
                Your instance has expired. To extend your trial, please reach out to <strong>product@tellius.com</strong>
            </div>
        </div>
    );
}

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            companyName: "",
            errors: {},
            isSubmitted: false,
            formValid: false,
            isTerminated: false,
            isExpired: false
        };
    }
    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        if (this.props.auth.user.role == "ADMIN") {
            this.props.history.push("/deployments");
        }
        this.checkValidity();
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        if (this.props.auth.user.role == "ADMIN") {
            this.props.history.push("/deployments");
        }
        const { user } = nextProps.auth;
        this.setState({
            isTerminated: user.isTerminated,
            isExpired: user.isExpired
        });
        if (!user.isTerminated && !user.isExpired) {
            if (user.companyName) {
                this.props.logoutUser();
                const url = `https://${user.companyName}.trial.tellius.com`;
                window.location.href = url;
            }
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    checkValidity() {
        const { user } = this.props.auth;
        const userData = {
            workEmail: user.workEmail,
        };
        this.props.checkUserValidity(userData);
    }
    validateForm() {
        var errors = {};
        if (this.state.companyName && !(/^[a-z0-9\-]+$/.test(this.state.companyName))) {
            errors.companyName = "only lower case characters, digits and hyphens allowed";
        }
        if (!this.state.companyName) {
            errors.companyName = "Company name is required";
        }
        this.setState({
            errors,
            formValid: Object.keys(errors).length === 0
        });
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value }, this.validateForm);
    };
    onSubmit = e => {
        e.preventDefault();
        const { user } = this.props.auth;
        const companyData = {
            companyName: this.state.companyName,
            workEmail: user.workEmail,
            firstName: user.firstName,
            lastName: user.lastName
        };
        this.props.createDeployment(companyData, this.props.history);
        this.setState({ isSubmitted: true });
    };
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    render() {
        const { user, companyName } = this.props.auth;
        const { isTerminated, isExpired } = user;
        const { errors, isSubmitted } = this.state;
        if (errors) {
            this.state.isSubmitted = false;
        }
        const companyMessage = this.props.dashboard.companyPayload;
        const createDeploymentPayload = this.props.dashboard.createDeploymentPayload;

        let html;

        if (isTerminated) {
            html = <TerminatedText />
        } else if (isExpired) {
            html = <ExpiredText />
        } else {
            html =
                <div>
                    <div className="heading-desc row">
                        <strong className="col s12 m12 l12">
                            You are the first in your company who is joining Tellius. Start with creating a new instance:
                        </strong>
                    </div>
                    <form noValidate onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col s6 m6 l6">
                                <label className="tellius-label">&nbsp;</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.companyName}
                                    error={errors.companyName}
                                    id="companyName"
                                    maxLength={14}
                                    placeholder="Company name"
                                    type="email"
                                    className={classnames("tellius-input", {
                                        invalid: errors.companyName
                                    })}
                                />.trial.tellius.com
                            <br />
                                <span className="red-text">
                                    {errors.companyName}
                                </span>
                            </div>
                        </div>
                        <div className="row valign-wrapper" style={{ "marginTop": "100px" }}>
                            <div className="col s8 m8 l8">
                            </div>
                            <div className="col s4 m4 l4 right-align">
                                <button
                                    type="submit"
                                    className={classnames("tellius-button btn btn-medium waves-light hoverable", {
                                        hide: isSubmitted,
                                        show: !isSubmitted
                                    })}
                                    disabled={!this.state.formValid}
                                >
                                    Create
                            </button>
                                <div className={classnames("preloader-wrapper small active", {
                                    show: isSubmitted,
                                    hide: !isSubmitted
                                })} id="waitCursor">
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div><div className="gap-patch">
                                            <div className="circle"></div>
                                        </div><div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
        }

        return (
            <div className="row">
                <div className="heading row">
                    <div className="col s12 m12 l12">
                        <b>Welcome, {user.firstName}</b>
                    </div>
                </div>
                {html}
            </div>
        );
    }
}
Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    setCompanyName: PropTypes.func.isRequired,
    createDeployment: PropTypes.func.isRequired,
    checkUserValidity: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    dashboard: state.dashboard,
    auth: state.auth,
    errors: state.errors,
});
export default connect(
    mapStateToProps,
    { logoutUser, setCompanyName, createDeployment, checkUserValidity }
)(Dashboard);