import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING,
    SET_REGISTRATION_SUCCESS,
    SET_VERIFICATION_SUCCESS,
    SET_APPROVAL_SUCCESS,
    SET_REJECTION_SUCCESS,
    SET_RESEND_SUCCESS
} from "./types";
// Register User
export const registerUser = (userData, history) => dispatch => {
    axios
        .post("/api/users/register", userData)
        .then(res => {
            dispatch(setRegistrationSuccess(res.data));
            history.push("/success");
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Confirm User
export const confirmUser = confirmationData => dispatch => {
    axios
        .post("/api/users/confirmation", confirmationData)
        .then(res => {
            // Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch(setVerifiedSuccess(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Approve User
export const approveUser = approvalData => dispatch => {
    axios
        .post("/api/users/approve", approvalData)
        .then(res => {
            dispatch(setApproveSuccess(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Reject User
export const rejectUser = rejectionData => dispatch => {
    axios
        .post("/api/users/reject", rejectionData)
        .then(res => {
            dispatch(setRejectionSuccess(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Resend confirmation for User
export const resend = resendData => dispatch => {
    axios
        .post("/api/users/resend", resendData)
        .then(res => {
            dispatch(setResendSuccess(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Login to get user token
export const loginUser = userData => dispatch => {
    axios
        .post("/api/users/login", userData)
        .then(res => {
            // Save to localStorage
            // Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            //console.log({decoded})
            // Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Check user validity
export const checkUserValidity = userData => dispatch => {
    axios
        .post("/api/users/checkUserValidity", userData)
        .then(res => {
            // Save to localStorage
            // Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            //console.log({decoded})
            // Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};
// Set Registration successfull
export const setRegistrationSuccess = success => {
    return {
        type: SET_REGISTRATION_SUCCESS,
        payload: success
    };
};
// Set Verification successfull
export const setVerifiedSuccess = success => {
    return {
        type: SET_VERIFICATION_SUCCESS,
        payload: success
    };
};
// Set Approval successfull
export const setApproveSuccess = success => {
    return {
        type: SET_APPROVAL_SUCCESS,
        payload: success
    };
};
// Set Rejection successfull
export const setRejectionSuccess = success => {
    return {
        type: SET_REJECTION_SUCCESS,
        payload: success
    };
};
// Set Resend successfull
export const setResendSuccess = success => {
    return {
        type: SET_RESEND_SUCCESS,
        payload: success
    };
};
// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};
// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};

