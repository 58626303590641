import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { resend } from "../../actions/authActions";
import telliusLogo from "../../assets/sucess.png";

class Success extends Component {
    constructor() {
        super();
        this.state = {
            errors: {}
        };
    }
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
        if (!this.props.auth.registrationMessage) {
            this.props.history.push("/login");
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
        if (!this.props.auth.registrationMessage) {
            this.props.history.push("/login");
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    onResend = (e) => {
        if (e) {
            e.preventDefault();
        }
        var workEmail = this.props.auth.registrationPayload.workEmail;
        const resendData = {
            workEmail: workEmail
        };
        this.props.resend(resendData);
    };
    render() {
        const message = this.props.auth.registrationMessage;
        const email = this.props.auth.registrationPayload.workEmail;
        const { errors } = this.state;
        const resendMessage = this.props.auth.resendPayload;
        return (
            <div className="valign-wrapper" style={{ "height": "100vh" }}>
                <div className="row col s12 m12 l12 center">
                    <div className="heading row">
                        <div className="col s12 m12 l12">
                            <b>You are almost there!</b>
                        </div>
                    </div>
                    <div className="heading-desc row">
                        <div className="col s12 m12 l12">
                            Thanks for signing up. A verification link has been sent to <b>{email}</b>.
                        </div>
                        <div className="col s12 m12 l12 mt5">
                            Please check and verify to activate your account.
                        </div>
                    </div>
                    <div className="row imageDiv">
                        <div className="col s12 m12 l12">
                            <img src={telliusLogo} alt="logo" style={{ "height": '220px' }} />
                        </div>
                    </div>
                    <div className="heading-desc row">
                        <div className="col s12 m12 l12">
                            <span>Didn't receive email? </span>
                            <span>
                                <button onClick={this.onResend} style={{ lineHeight: '14px' }} className="tellius-link-button btn-flat waves-teal">Resend Email</button>
                            </span>
                        </div>
                        <strong className="col s12 m12 l12">
                            {resendMessage.message} {errors.msg}
                        </strong>
                    </div>
                </div>
            </div>
        );
    }
}
Success.propTypes = {
    resend: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { resend }
)(Success);