import React, { Component } from "react";

class Plans extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="tellius-plans row col s12 m12 l12 center">
                <div lyout="column" className="header-container flex">
                    <div className="main-title"><span className="h1">Tellius Plan</span></div>
                    <div className="description"><span>Choose the plan that is right for your team</span></div>
                    <div role="button" aria-label="full details" ng-click="vm.viewFullDetails()" className="planDetails layout-align-center-center layout-row flex"><span>View full plan details</span></div>
                </div>
                <div className="plan-cards row col s12 m12 l12 center">
                    <div className="plan-card active  layout-column flex">
                        <div className="plan-active-head hide"></div>
                        <div className="plan-card-content">
                            <div className="top-half">
                                <div className="header"><span>Free Trial</span></div>
                                <div className="costing"><span className="validity ">No commitments. No Fees.</span>
                                    <br />
                                    <span className="validityHighlight">14 days</span>
                                    <span className="validityTagLine ">free trial.</span></div>
                                <div className="purchase-btn mb10 layout-align-center-center layout-row">
                                    <span className="currentPlan ">CURRENT PLAN</span>
                                </div>
                            </div>
                            <div className="basic-info">
                                <div className="package layout-align-center-center layout-row">

                                </div>
                                <div className="baseInfo"><span className="heavy ">1GB </span><span className="text">total data volume</span></div>
                                <div className="baseInfo"> <span className="heavy ">100MB</span>
                                    <span className="text "> maximum </span>
                                    <span className="text"> dataset size</span></div>
                                <div className="baseInfo">
                                    <span className="text ">Up to</span>
                                    <span className="heavy "> 5 </span><span className="text">users</span></div>
                                <div className="baseInfo"><span className="heavy ">2 </span><span className="text">concurrent jobs</span></div>

                                <div className="baseInfo "><span className="text ">Shared compute resources</span></div>

                                <div className="baseInfo "><span className="text ">Cloud</span></div>

                            </div>
                            <div className="separator">
                                <div className="separator-line"></div>
                            </div>
                            <div className="features">

                                <div className="feature "><i className="mr10 icon feature-icon icon-data"></i><span className="heavy ">Limited data prep &amp; modeling</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-search2"></i><span className="heavy ">Search</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-dashboard2"></i><span className="heavy ">Vizpad</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-insights4"></i><span className="heavy ">Discover</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-prediction"></i><span className="heavy ">Predict</span></div>

                            </div>
                        </div>
                    </div>

                    <div className="plan-card active  layout-column flex">
                        <div className="plan-active-head hide"></div>
                        <div className="plan-card-content">
                            <div className="top-half">
                                <div className="header"><span>Enterprise Trial</span></div>
                                <div className="costing"><span className="validity ">Customize your own package. To get a price</span>
                                    <br />
                                    <span className="validityTagLine ">quote please contact our Sales team.</span></div>
                                <div className="purchase-btn mb10 layout-align-center-center layout-row">
                                    <span role="button" aria-label="get in touch" ng-click="vm.getInTouch()" className="nextPlan  ">GET IN TOUCH</span>

                                </div>
                            </div>
                            <div className="basic-info">
                                <div className="package layout-align-center-center layout-row">
                                    <span>Example Package</span>

                                </div>
                                <div className="baseInfo"><span className="heavy ">100GB </span><span className="text">total data volume</span></div>
                                <div className="baseInfo"> <span className="heavy ">Unlimited</span>
                                    <span className="text"> dataset size</span></div>
                                <div className="baseInfo">
                                    <span className="heavy "> Unlimited </span><span className="text">users</span></div>
                                <div className="baseInfo"><span className="heavy ">Unlimited </span><span className="text">concurrent jobs</span></div>

                                <div className="baseInfo "><span className="text ">Shared server license</span></div>

                                <div className="baseInfo "><span className="text ">Enterprise</span></div>

                            </div>
                            <div className="separator">
                                <div className="separator-line"></div>
                            </div>
                            <div className="features">

                                <div className="feature "><i className="mr10 icon feature-icon icon-data"></i><span className="heavy ">Limited data prep &amp; modeling</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-search2"></i><span className="heavy ">Search</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-dashboard2"></i><span className="heavy ">Vizpad</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-insights4"></i><span className="heavy ">Discover</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-prediction"></i><span className="heavy ">Predict</span></div>

                                <div className="feature "><i className="mr10 icon feature-icon icon-lock_outline"></i><span className="heavy ">Security / Authorization</span></div>

                            </div>
                        </div>
                    </div>

                </div>
                <div id="fullPlanDetails" className="comparison layout-column flex">
                    <div className="comparison-header"><span>Compare plan details</span></div>
                    <div className="comparison-tables">

                        <table className="tellius-table ">
                            <thead>
                                <tr>
                                    <td className="left-header"><i className="mr10 icon icon-data"></i><span>Working with Data</span></td>

                                    <td className="type-header "><span>Free Trial</span></td>

                                    <td className="type-header "><span>Enterprise trial (customizable)</span></td>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="left-title"><span>Filesystem Connectors</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Cloud &amp; Datasource Connectors</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Visual Interactive Transformations</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Hadoop Connector</span></td>

                                    <td className="check-values "><i className="icon icon-minus"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                            </tbody>
                        </table>

                        <table className="tellius-table ">
                            <thead>
                                <tr>
                                    <td className="left-header"><i className="mr10 icon icon-search2"></i><span>Analyze by Asking Questions</span></td>

                                    <td className="type-header "><span>Free Trial</span></td>

                                    <td className="type-header "><span>Enterprise trial (customizable)</span></td>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="left-title"><span>Voice &amp; Text Search Experience</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Interactive Smart Charts, best-fit chart</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Intelligent Suggestions</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Customizable Syntax for your Business</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                            </tbody>
                        </table>

                        <table className="tellius-table ">
                            <thead>
                                <tr>
                                    <td className="left-header"><i className="mr10 icon icon-dashboard2"></i><span>Visual Analysis</span></td>

                                    <td className="type-header "><span>Free Trial</span></td>

                                    <td className="type-header "><span>Enterprise trial (customizable)</span></td>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="left-title"><span>25+ built in charts</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Interactive Charting</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Dashboards</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                            </tbody>
                        </table>

                        <table className="tellius-table ">
                            <thead>
                                <tr>
                                    <td className="left-header"><i className="mr10 icon icon-insights4"></i><span>A Level Deeper with Insights</span></td>

                                    <td className="type-header "><span>Free Trial</span></td>

                                    <td className="type-header "><span>Enterprise trial (customizable)</span></td>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="left-title"><span>Discovery Anomalies &amp; Key Drivers</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Intelligent Recommendations</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Automated Feed Alerts on Important KPI's</span></td>

                                    <td className="check-values "><i className="icon icon-minus"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Compare Cohorts</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                            </tbody>
                        </table>

                        <table className="tellius-table ">
                            <thead>
                                <tr>
                                    <td className="left-header"><i className="mr10 icon icon-prediction"></i><span>Learn today Predict Tomorrow</span></td>

                                    <td className="type-header "><span>Free Trial</span></td>

                                    <td className="type-header "><span>Enterprise trial (customizable)</span></td>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="left-title"><span>AutoML</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Point-n-Click</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Predictive Analysis via Explainable AI</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                            </tbody>
                        </table>

                        <table className="tellius-table ">
                            <thead>
                                <tr>
                                    <td className="left-header"><i className="mr10 icon icon-user-group"></i><span>Teams &amp; Collaboration</span></td>

                                    <td className="type-header "><span>Free Trial</span></td>

                                    <td className="type-header "><span>Enterprise trial (customizable)</span></td>

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="left-title"><span>Organize content via Projects</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>Roles, Groups &amp; Permissions</span></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                                <tr>
                                    <td className="left-title"><span>APIs/ Embedding Support </span></td>

                                    <td className="check-values "><i className="icon icon-minus"></i></td>

                                    <td className="check-values "><i className="icon icon-checkmark-1"></i></td>

                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        );
    }
}
export default Plans;