import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { deleteDeployment, expireDeployment, listDeployment, terminateDeployment, trialLicenseExtension } from "../../actions/dashboardActions";
import classnames from "classnames";
import Moment from 'react-moment';
import copy from "copy-to-clipboard";
import Modal from '../modal/modal'
import _ from "lodash";

class Deployments extends Component {
    constructor() {
        super();
        this.state = {
            deployments: [],
            errors: {},
            showPopup: false
        };
    }
    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else if (this.props.auth.user.role != "ADMIN") {
            this.props.history.push("/dashboard");
        } else {
            this.fetchDeployment();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else if (nextProps.auth.user.role != "ADMIN") {
            this.props.history.push("/dashboard");
        } else {
            this.setState({
                deployments: nextProps.dashboard.deploymentListPayload
            })
            if (nextProps.errors) {
                this.setState({
                    errors: nextProps.errors
                });
            }
        }
    }

    fetchDeployment = () => {
        this.props.listDeployment();
    }
    onDelete = deployment => {
        const deploymentData = {
            workEmail: deployment.email
        };
        this.props.deleteDeployment(deploymentData);
    };
    onExpire = deployment => {
        const deploymentData = {
            workEmail: deployment.email
        };
        this.props.expireDeployment(deploymentData);
    };
    onTerminate = deployment => {
        const deploymentData = {
            workEmail: deployment.email
        };
        this.props.terminateDeployment(deploymentData);
    };

    showExtendPopup = deployment => {
        this.setState({...this.state, showPopup : true, selectedDeployment: deployment})
    }

    handleClose = () => {
        this.setState({...this.state, showPopup: false, selectedDeployment: undefined})
    }
    formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0'); // Days are 1-based

        return `${year}-${month}-${day}`;
    }
    handleUpdate = (date) => {
        const deloymentData = {
            companyName: _.get(this.state, 'selectedDeployment.companyName'),
            extendTill: this.formatDate(date)
        }
        this.props.trialLicenseExtension(deloymentData);
        this.setState({...this.state, showPopup: false, selectedDeployment: undefined})
    }

    setExpiryDate = deployment => {
        const deploymentData = {
            workEmail: deployment.email
        };
        this.props.terminateDeployment(deploymentData);
    }

    logout = e => {
        e.preventDefault();
        this.props.logoutUser();
        this.props.history.push("/login");
    }
    onCopyLink = verificationLink => {
        copy(verificationLink);
    }
    render() {
        const { errors, deployments, showPopup } = this.state;
        return (
            <div className="row mt20">
                <div className="col s12 m12 l12 right-align">
                    <button
                        className="blue darken-3 btn btn-medium waves-light hoverable"
                        type="button" ng-transclude="" onClick={(e) => this.logout(e)}>
                        <i className="material-icons left">power_settings_new</i>
                        Logout
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th> Index </th>
                            <th> Deployment </th>
                            <th> Email </th>
                            <th> Created </th>
                            <th> Expiry </th>
                            <th> Status </th>
                            <th> Action </th>
                            <th> Confirmation URL </th>
                        </tr>
                    </thead>
                    <tbody>
                        {deployments.map((deployment, index) => {
                            const isExpired = deployment.status == 'Expired';
                            const isTerminated = deployment.status == 'Terminated';
                            const isNotVerified = deployment.status == 'Not Verified';
                            const createdDate = deployment.createdAt;
                            const expiryDate = deployment.expiryDate;
                            const verificationLink = `${window.location.origin}${deployment.verificationLink}`;
                            let status = true;
                            switch (deployment.status) {
                                case 'Success':
                                case 'Expired':
                                case 'Terminated':
                                    status = false;
                                    break;
                                default:
                                    status = true;
                            }
                            return <tr>
                                <td>{index + 1}</td>
                                <td>{deployment.companyName || deployment.dnsmappedUrl}</td>
                                <td>{deployment.email}</td>
                                <td><Moment fromNow>{createdDate}</Moment> <br /> <Moment className="timeExact" format="YYYY-MMM-DD HH:mm">{createdDate}</Moment></td>
                                <td>
                                    {!isNotVerified &&
                                        <div className={classnames({
                                            hide: !expiryDate,
                                            show: expiryDate
                                        })}><Moment fromNow>{expiryDate}</Moment> <br /> <Moment className="timeExact" format="YYYY-MMM-DD HH:mm">{expiryDate}</Moment>
                                        </div>
                                    }
                                    {!isNotVerified &&
                                        <div className={classnames({
                                            hide: expiryDate,
                                            show: !expiryDate
                                        })}>{'--'}</div>
                                    }
                                </td>
                                <td>{deployment.status}</td>
                                <td>
                                    {!isNotVerified &&
                                        <section layout="row" layout-sm="column" layout-align="center center" layout-wrap="" className="layout-wrap layout-sm-column layout-align-center-center layout-row">
                                            <button className={classnames("btn grey md-raised md-warning md-button md-ink-ripple mr10", {
                                                hide: isExpired || isTerminated,
                                                show: !isExpired && !isTerminated
                                            })} type="button" ng-transclude="" onClick={() => this.onTerminate(deployment)}>Terminate</button>
                                            <button className={classnames("btn amber md-raised md-warning md-button md-ink-ripple mr10", {
                                                hide: isExpired,
                                                show: !isExpired
                                            })} type="button" ng-transclude="" onClick={() => this.onExpire(deployment)}>Expire</button>
                                            <button className={classnames("btn amber md-raised md-warning md-button md-ink-ripple mr10", {
                                                hide: isTerminated,
                                                show: !isTerminated
                                            })} type="button" ng-transclude="" onClick={() => this.showExtendPopup(deployment)}>Extend</button>
                                            <button className="btn red lighten-2 md-raised md-warn md-button md-ink-ripple" type="button" ng-transclude="" onClick={() => this.onDelete(deployment)}>Delete</button>
                                        </section>
                                    }
                                </td>
                                <td>
                                    {deployment.verificationLink && status &&
                                        <button className="btn btn-floating green lighten-2 md-raised md-success md-button md-ink-ripple tooltipped" data-position="bottom" data-tooltip={verificationLink} type="button" ng-transclude="" onClick={() => this.onCopyLink(verificationLink)}><i className="material-icons left">content_copy</i></button>
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <Modal isOpen={showPopup} onClose={this.handleClose} onUpdate={this.handleUpdate}/>
            </div>
        );
    }
}
Deployments.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    deleteDeployment: PropTypes.func.isRequired,
    expireDeployment: PropTypes.func.isRequired,
    terminateDeployment: PropTypes.func.isRequired,
    listDeployment: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    dashboard: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    dashboard: state.dashboard,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { logoutUser, deleteDeployment, expireDeployment, terminateDeployment, listDeployment, trialLicenseExtension }
)(Deployments);