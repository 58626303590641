import React, { useState } from 'react';
import './modal.css'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const Modal = ({ isOpen, onClose, onUpdate, children }) => {
    const [date, setDate] = useState(new Date());
    const today  = new Date();
    if (!isOpen) return null;

    const checkDisable = ({ activeStartDate, date, view }) => {
        return (date <= new Date())
    }

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>X</button>
                <div className="modal-title mb15">Extend license date</div>
                <Calendar
                    onChange={setDate}
                    tileDisabled={checkDisable}
                    value={date}
                />
                <button disabled={date <= today} className="btn amber md-raised md-warning md-button md-ink-ripple mr10 mt15" onClick={() => onUpdate(date)}>Update</button>
            </div>
        </div>
    );
};

export default Modal;