import React, { Component } from "react";

class Terms extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="row col s12 m12 l12 center">
                <div className="heading row">
                    <div className="col s12 m12 l12">
                        <b>Tellius Cloud License Terms and Conditions</b>
                    </div>
                </div>
                <div className="heading-desc row">
                    <div className="col s12 m12 l12 tal">
                        <p>
                            BY CLICKING ON THE “I ACCEPT” BUTTON OR BY OTHERWISE EXERCISING ANY RIGHTS PROVIDED BELOW TO USE THE TELLIUS SERVICES AND/OR THE TELLIUS SOFTWARE (EACH AS DEFINED BELOW) OFFERED BY TELLIUS INC., A DELAWARE CORPORATION (“TELLIUS”), INCLUDING, FOR EXAMPLE, BY ACCESSING OR USING THE TELLIUS SOFTWARE OR THE TELLIUS SERVICES, THIS ENTITY, INDIVIDUAL OR ORGANIZATION (“CUSTOMER”) CONSENTS TO BE BOUND BY THIS AGREEMENT, OR, IF APPLICABLE, BY THE TERMS OF A CURRENTLY EFFECTIVE WRITTEN AGREEMENT REGARDING THE USE OF THE PRODUCT.IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, DO NOT ACCESS OR USE TELLIUS.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            Tellius Software and Services Subscription Terms and Conditions (the <b>“Terms”</b>) are entered into as of the order effective Date and are incorporated as part of the agreement between customer (“Customer”)  and Tellius, Inc. (“Tellius”), located at 12700 Sunrise Valley Dr, #305, Reston, VA 20191. Tellius  and Customer are each a <b>“Party”</b> and together are the <b>“Parties”</b>.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>1. SOFTWARE LICENSE RIGHTS</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>1.1. <u>Evaluation Access.</u></b> Tellius has made available free, trial or evaluation access to Tellius cloud hosted access (“Evaluation Access”) to the Customer. Customer may use such access solely for the purpose of evaluating Tellius software to determine whether to purchase a subscription from Tellius. Customer may not use Evaluation Access for any other purposes, including but not limited to competitive analysis, commercial, professional or for-profit purposes. Evaluation Access is limited to fourteen (14) days from the date customer activates Evaluation Access, unless otherwise specified by Tellius in the applicable Order Document or a separate writing from Tellius (“Evaluation Period”). Evaluation license is only issued for a valid company email address and use of email domains like yahoo, gmail and other public domains for creating evaluation account is not permitted. Unless Customer purchase a subscription for Tellius, Evaluation Access may be terminated and, in any event the right to access and use Tellius software automatically expires at the end of the Evaluation Period. Upon any termination or expiration of the Evaluation Period, Customer Evaluation Access will cease and will no longer have access to any Customer Data used in connection with such Evaluation Access. Notwithstanding any other provision of this Agreement, Evaluation Access is provided “AS IS” without warranty or support of any kind, express or implied. Tellius may terminate Customer Evaluation Access at any time for any reason and without liability of any kind. IF CUSTOMER SUBSEQUENTLY PURCHASE A SUBSCRIPTION TO TELLIUS SOFTWARE, EVALUATION ACCESS SHALL IMMEDIATELY TERMINATE AND CUSTOMER EXPRESSLY AGREE THAT, UNLESS CUSTOMER HAS A SEPARATE SIGNED AGREEMENT GOVERNING ACCESS TO AND USE OF TELLIUS SOFTWARE, THIS AGREEMENT, AND THE TERMS AND CONDITIONS HEREIN, SHALL GOVERN CUSTOMER USE OF SUCH NON-EVALUATION VERSION.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>1.2.</b> During the Term, and subject to agreement terms and conditions, Tellius hereby grants Customer a limited, nonexclusive, nontransferable, nonsublicensable, revocable right and license to (i) access and use the Tellius Software via a web browser or web-enabled device and (ii) use the Software solely for the purposes of accessing and using the Services. If Tellius provide Customer with any Software under an open source license, there may be provisions in those licenses that conflict with these Terms, in which case the open source provisions will apply with respect to the code to which those provisions apply.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>1.3.</b> Tellius may update the Software or modify the services from time to time without notice to Customer. Tellius will notify Customer in advance if a change is material, other than those changes which enhance or extend any features or functionality of the Software or Service.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>1.4.</b> Customer is solely responsible for the acts and omissions of the Authorized Users, including their use of the Services, Software, and Customer Data (defined in Section 3.1), and/or any use of the Services made using an Authorized User’s username and password. Customer warrant that Customer will only permit Authorized Users to access the Tellius Softwareand that Customer and Customer Authorized Users will maintain the security of their usernames and passwords. Customer will notify Tellius immediately if Customer suspect or become aware of any unauthorized use of the Services or if an Authorized User’s username or password is lost or stolen.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>1.5. <u>Restrictions.</u></b> Customer and Customer Authorized Users must not: (a) use the Tellius Software and Services to provide services to third parties or otherwise reproduce, license, sell, rent, lease, outsource, act as service bureau, or sublicense the Tellius  Software or Services; (b) use the Tellius  Software or Services(including by uploading Customer Data, downloading or sharing other content or information, or emailing, calling, or communicating with others through the Tellius  Services) in any manner that Tellius  reasonably believes is harmful, infringing, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy or right of publicity, hateful, or otherwise violates the rights of another or does not comply with applicable law; (c) except as permitted by applicable law, decompile, decipher, disassemble, translate, modify, prepare derivative works of, reverse engineer or otherwise attempt to access or derive the source code or other trade secrets from the Tellius  Software or Services; (d) interfere with or disrupt, disable, damage, impair, or overburden the Tellius  Software or Services, including by transmitting viruses or other malicious code or using the Tellius  Services to spam others.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>2. CUSTOMER DATA</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>2.1.</b> Any content, materials, software, data, or other information that Customer or Customer Authorized Users provide to Tellius through the Tellius Software <b>(“Customer Data”)</b> is and will remain the Customer property. Customer hereby grant Tellius a non- exclusive right and license to use and reproduce Customer Data solely for the purposes of providing the Services to Customer, and providing Support and analysis. Tellius may also have access to and see Customer Data when Tellius  provide Support or if Tellius  provide other assistance or training to Customer or Customer Authorized Users. Nothing in this Agreement will restrict Tellius from collecting, using and analyzing general information and data from its customers (including Customer) for purposes of improving and enhancing the quality and nature of Services, or to market or publish general information and statistics, provided that Tellius does not specifically identify Customer or disclose any personally identifiable information in the course of collecting, using, analyzing, marketing or publishing that information or data. Customer warrant that Customer Data, and the provision of Customer Data to Tellius through the Tellius Services, does not infringe or misappropriate any third party’s intellectual property rights, or violate any applicable law.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>2.2.</b> Customer Data is not visible to any third parties unless Customer and Customer Authorized Users use the Services to share Customer Data with others. If Customer Authorized Users choose to share Customer Data, Customer are solely responsible for that disclosure, and what any third parties do with Customer Data. Likewise, Customer are solely responsible for any actions Customer Authorized Users take with respect to Customer Data, including deleting or corrupting Customer Data. Customer acknowledge that Tellius  are not responsible for the disclosure of Customer Data by Customer or Customer agents (including Customer Authorized Users), to any third parties.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>2.3.</b> Customer agree not to use the Tellius subscription service to collect, manage or process sensitive information.  Tellius  will not have any liability that may result from Customer use of the subscription service to collect or manage sensitive information.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>2.4.</b> Customer are solely responsible for Customer Data, including the accuracy and integrity of Customer Data and for correcting errors and omissions in Customer Data. Customer acknowledge that Tellius has no obligation to monitor any information on the Tellius Softwareand that Tellius  are not responsible for the accuracy, completeness, appropriateness, or legality of Customer Data or any other information or content Customer may be able to access using the Services.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>2.5.</b> Tellius will maintain industry-standard physical and logical data security systems to prevent unauthorized access to Tellius servers . Tellius will use commercially reasonable efforts to remedy any breach of security or unauthorized access to Customer Data. Tellius system has security controls in place at user access level to prevent access for unauthorized users as well as industry standard encryption mechanism in place for data in motion as well as data at rest.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>3. PAYMENT, TAXES</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>3.1.</b> Tellius will invoice Customer for amounts due under the Agreement and Customer will pay all Fees as specified in the Order. Customer will also pay all costs incurred by Tellius to collect undisputed amounts due, including reasonable attorneys’ fees, whether or not litigation is commenced.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>3.2.</b> Customer will pay all applicable sales, use, service, value- added, consumption or other taxes associated with the Services Customer purchase.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>3.3.</b> Except as otherwise expressly provided in the applicable Sales Order, the license fees for each Software license shall automatically be increased from the applicable fees for the last annual period of the expiring Initial or Renewal Term by 5% per annum for each Renewal Term (as defined below) for the same licenses on the same terms as were originally purchased.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>4. TERM AND TERMINATION</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>4.1.</b> This Agreement will commence on the Order Effective Date and will continue for the Initial Term as agreed in the contract, after which it will automatically renew for successive terms of twelve (12) months (each a “Renewal Term”, and together with the Initial Term, the “Term”), unless either party provides written notice to the other party at least 30 days before expiration of the term. In case no term is set forth in Sales order, license shall continue in force for one (1) year from the date of such order. In case of <u>Evaluation license</u>, the term will expire after 14 days, unless customer decides to purchase subscription license.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>4.2.</b> This Agreement may only be terminated as follows: (A) either Party may terminate this Agreement at any time, immediately upon written notice to the other, if: (a) the other Party attempts to assign this Agreement or delegate any obligation under the Agreement without the non- assigning Party’s consent, except as otherwise permitted hereunder; or (b) any assignment is made of the other Party's business for the benefit of creditors, or if a petition in bankruptcy is filed by or against the other Party, or if a receiver or similar officer is appointed to take charge of all or part of the other Party's property, or if the other Party is adjudicated a bankrupt; (B) either Party may terminate this Agreement upon written notice to the other if the other Party is in breach of any material term or condition of this Agreement and the breaching Party fails to cure the breach within thirty (30) days of receipt of notice of that breach; or (C) either Party may terminate the auto renewal of this Agreement upon written notice to the other Party, which notice is given at least thirty (30) days prior to the end of the then-current Initial Term or Renewal Term, as the case may be.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>4.3.</b> If Customer account is thirty (30) days or more overdue, in addition to any other remedies available at law or in equity, Tellius will have the right to suspend Customer access to Tellius software until such amounts are paid in full.  Within a reasonable time after written notice by Tellius that Customer use of Tellius system is having a detrimental impact on Tellius’s ability to deliver services to Customer or its other customers, Tellius reserves the right to suspend, or limit Customer access to Tellius system.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>4.4.</b> Upon termination of this Agreement, Tellius will cease providing the Services and promptly invoice Customer for any unpaid amounts owed, and Customer will pay Tellius for all Services rendered and expenses incurred prior to the effective date of termination. Tellius will, within thirty (30) days of termination of this Agreement, irretrievably delete Customer Data on Tellius Servers and customer will have option to save and export any analysis created in Tellius system during the engagement term. Customer acknowledge that if Customer access to the Services is suspended or the Agreement is terminated, Customer will no longer have access to Customer Data stored on the Tellius Services.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>5. NONDISCLOSURE OF CONFIDENTIAL INFORMATION</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>5.1.</b> During the Term and for a period of five (5) years after termination of the Agreement (except for trade secrets, which shall be held in confidence for so long as they constitute trade secrets), each Party (the “Receiving Party”) that receives Confidential Information (as defined below) of the other Party (the "Disclosing Party") will not use, other than in connection with the provision or receipt of the Services, or disclose to anyone, other than officers, employees or representatives of the Receiving Party with a need to know for purposes of this Agreement and who are subject to confidentiality obligations consistent with the terms of this Agreement (“Representatives”), any Confidential Information disclosed to the Receiving Party by or on behalf of the Disclosing Party. The Receiving Party will safeguard disclosure of such Confidential Information to the same extent that Receiving Party safeguard’s its own Confidential Information, but in any case will at a minimum use reasonable care. Upon request of the Disclosing Party, the Receiving Party will promptly return to the Disclosing Party or destroy, certifying in writing to the Disclosing Party the destruction of such Confidential Information, the Disclosing Party’s Confidential Information in its possession or under its control.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>5.2.</b> “Confidential Information” means all information, material and data of the Disclosing Party which (i) is labeled or designated in writing as confidential or proprietary, (ii) the Receiving Party is advised is proprietary or confidential, or (iii) in view of the nature of such information and/or the circumstances of its disclosure, the Receiving Party knows or reasonably should know is confidential or proprietary. Confidential Information includes, without limitation, the terms and conditions of this Agreement, the Services and Software, Customer Data, and all information relating to the Disclosing Party’s business plans, marketing plans, customers, technology, employee and organizational information, product designs, product plans and financial information.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>5.3.</b> These confidentiality obligations will not apply to any information which (i) is or becomes publicly known without any fault of or participation by the Receiving Party or its Representatives, (ii) was in Receiving Party's possession prior to the time it was received from Disclosing Party or came into Receiving Party's possession thereafter, in each case lawfully obtained from a source other than Disclosing Party or its Representatives and not subject to any obligation of confidentiality or restriction on use, or (iii) is required to be disclosed by judicial, arbitral or governmental order or process or operation of law, in which event the Receiving Party will, unless prohibited by law, notify the Disclosing Party of the requirement of disclosure before making such disclosure and will comply with any protective order or other limitation on disclosure obtained by the Disclosing Party; or (iv) is independently developed by the Receiving Party without reference to the Disclosing Party's Confidential Information.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>5.4.</b> Any Confidential Information in a manner inconsistent with the provisions of this Agreement may cause the Disclosing Party irreparable damage for which remedies other than injunctive relief may be inadequate, and both parties agree that the Disclosing Party may request injunctive or other equitable relief seeking to restrain such use or disclosure.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>6. WARRANTIES</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>6.1. Limited Warranty.</b> Tellius warrants that, for a period of sixty (90) days after the date of shipment, the Software will perform substantially in accordance with the specifications in the applicable documentation. Tellius does not warrant that OEM’s operation of the Software will be bug-free, uninterrupted or it will be compatible with all of OEM’s or End customer’s software configurations. In case of any issues with the software, Tellius shall use commercially reasonable efforts to remedy any failure of the Software to materially confirm to its documentation, provided that OEM is current in its payment obligations. If Tellius is unable to remedy such failure within a reasonable time after notice has been provided, OEM shall be entitled to terminate this Agreement and to receive a refund of license fees paid hereunder for the then current annual period of the Term of the license granted hereunder for such Software.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>6.2.</b> WITH THE EXCEPTION OF THOSE EXPRESS WARRANTIES MADE IN THIS SECTION, TO THE MAXIMUM EXTENT PERMITTED BY LAW, TELLIUS  DISCLAIMS ALL WARRANTIES WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE, NON- INFRINGEMENT, AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>7. INTELLECTUAL PROPERTY</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>7.1.</b> Customer acknowledge that, as between the Parties and except as licensed in Section 2.2, Tellius  owns and retains all right, title and interest in the Intellectual Property Rights in the Services and Software. Except as licensed under Section 3, Customer own and retain all right, title, and interest in the Intellectual Property Rights in Customer Data. <b>“Intellectual Property Rights”</b> means: (i) copyrights and other rights associated with works of authorship; (ii) trademark and trade name rights and similar rights; (iii) trade secret rights; (iv) patents, designs, algorithms, utility models, and other industrial property rights, and all improvements thereto; and (v) all registrations, applications, renewals, extensions, continuations, divisions, or reissues now or in the future.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>7.2.</b> Customer agree that Tellius (or others authorized by Tellius) may freely use, disclose, reproduce, license, distribute, or otherwise exploit in any manner any feedback, comments, or suggestions Customer post in Tellius forums or otherwise provide about Tellius Software without any obligation to Customer, restriction of any kind (including on account of any Intellectual Property Rights), and without paying any compensation to Customer or any third party.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>7.3.</b> Customer acknowledge that information and content accessible through the Tellius  Services may be protected by Intellectual Property Rights of third parties. The Tellius  Services may contain links to websites or resources of others, however Tellius is  not responsible or liable for the accuracy, availability, content, products, or services of any third party. Customer are solely responsible and liable for Customer use of any third party websites and any third party content or information Customer copy, share, or download while using the Services. Tellius will respond to notices of alleged copyright infringement that comply with the law and are properly provided to us. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>8. DEFENSE AND INDEMNITY</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>8.1.</b> Tellius  will defend, indemnify and hold harmless Customer and its officers, directors, employees, members, agents, legal representatives, subsidiaries, affiliates, successors and permitted assigns from and against any claim, action, demand or proceeding by a third party (collectively <b>“Claims”</b>) resulting in liability, direct damages, cost, loss or expense, including court costs and reasonable attorney’s fees (collectively <b>“Losses”</b>) to the extent they result from a breach of any warranty set forth in Section 7.1. If a Loss is found by a court of competent jurisdiction to have been caused only in part by Tellius , then its liability hereunder will be only such amount as is attributable to its fault. Notwithstanding the foregoing, Tellius  shall have no liability under this section to the extent that an alleged infringement of Intellectual Property Rights arises from (i) use of the Tellius  Services and/or Software in combination with other equipment or software not provided or approved by us in writing, if such claim would have been avoided but for such combined use; (ii) any modification to the Software made by Customer or any other third party not approved by us in writing or permitted under this Agreement; (iii) Customer failure to install any Software updates provided by us; or (iv) use of the Tellius  Services and/or Software other than in the manner permitted or authorized under this Agreement. THE PROVISIONS OF THIS SECTION STATE THE SOLE, EXCLUSIVE, AND ENTIRE LIABILITY OF TELLIUS  TO CUSTOMER, AND CUSTOMER'S SOLE REMEDY, WITH RESPECT TO THE INFRINGEMENT OR MISAPPROPRIATION OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>8.2.</b> To the maximum extent permitted by law, Customer agrees to defend, indemnify and hold harmless Tellius, its affiliates and their respective directors, officers, employees and agents from and against any and all claims, actions, suits or proceedings, as well as any and all losses, liabilities, damages, costs and expenses (including reasonable attorneys’ fees) arising out of or accruing from (i) Customer’s use of the Software, (ii) any Products developed by Customer that infringe or threaten to infringe any copyright, trademark, trade secret, trade dress, patent or other intellectual property right of any person, including Tellius and (c) any non-compliance by Customer with this Agreement.  As a condition to Customer’s indemnity obligation Tellius shall give Customer prompt notice of any such claim, grant Customer sole control of the defense and/or settlement of any such claim (provided that Customer shall not enter into any settlement that admits liability on behalf of Tellius or imposes any obligations on Tellius other than payment of amounts indemnified hereunder) and provide reasonable assistance as requested by Customer.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>8.3.</b> The Party seeking indemnification under this Agreement will: (i) give the indemnifying Party prompt written notice of the Claim, (ii) tender to the indemnifying Party control of the defense and settlement of the Claim, and (iii) cooperate with the indemnifying Party in defending or settling the Claim. The indemnified Party will have the right to participate at its own expense in any indemnification action or related settlement negotiations using counsel of its own choice. Neither Party may consent to the entry of any judgment or enter into any settlement that adversely affects the rights or interests of the other Party without that Party’s prior written consent, which may not be unreasonably withheld. If a final injunction is obtained against Customer’s right to continue using the Services and/or Software or, if in Tellius opinion the Services and/or Software are likely to become the subject of a claim, Tellius  shall (a) obtain the right for Customer to continue to using the Services and Software; or (b) replace or modify the Services and/or Software so that they no longer infringe but function equivalently or (c) if neither (a) or (b) is commercially practicable, Tellius  shall terminate this Agreement and the license granted hereunder, and within thirty (30) days of the return of all Confidential Information to us by Customer, refund to Customer all unearned fees paid by Customer, if any, for any Services not yet performed.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>9. LIMITATION OF LIABILITY</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            EXCEPT IN CASE OF WILLFULL MISCONDUCT OR BREACH OF THE LICENSE SCOPE, ALL LIABILITY OF TELLIUS UNDER THIS AGREEMENT OR OTHERWISE SHALL BE LIMITED TO THE AMOUNT OEM HAS PAID TO TELLIUS FOR THE TELLIUS PRODUCTS DURING THE TWELVE (12) MONTHS PRECEDING THE EVENT. IN NO EVENT SHALL TELLIUS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, DAMAGES FOR LOSS OF PROFITS OR CONFIDENTIAL OR OTHER INFORMATION, OR OTHER LOSS ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF THE SOFTWARE.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10. GENERAL</b>
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.1.</b> Neither Party may assign this Agreement, or any of its rights or obligations under the Agreement, without prior written consent of the other Party, provided, however that either Party may assign its rights and obligations to any of its majority- owned affiliates or subsidiaries, or to any successor in interest to all or substantially all of such Party’s business or assets associated with the Services, provided that such successor is not a competitor of the other party.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.2.</b> Except as otherwise expressly set forth in these Terms, all notices given to the Parties under the Agreement will be in writing and will be given by nationally recognized overnight courier service, certified mail (return receipt requested), facsimile with electronic confirmation or personal delivery, if to Customer at the address indicated on the Order, and if to Tellius  at:
                            <br/>
                            <br/>
                            Tellius Inc,
                            <br/>
                            12700 Sunrise Valley Dr, #305
                            <br/>
                            Reston, VA 20191 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <u><b>10.3.</b> This Agreement is governed by the laws of the State of Virginia, without regard to its conflicts of law rules. Any legal proceeding arising out of or relating to this Agreement will be brought in the state and federal courts of Virginia.</u> Each Party consents to the exclusive jurisdiction and venue of such courts. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.4.</b> Tellius  may refer to Customer as a client of Tellius  in general listings of clients that Tellius  may make available on its website or in promotional or marketing materials.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.5.</b> If any provision of this Agreement is held invalid, illegal or unenforceable, the validity, legality or enforceability of the remaining provisions will in no way be affected or impaired. A Party does not waive any right under this Agreement by failing to insist on compliance with any term or by failing to exercise any right under this Agreement. Waiver of any provision of this Agreement is effective only if it is written and signed by the Party granting the waiver and will not imply a subsequent waiver of that or any other provision of this Agreement.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.6.</b> The rights and remedies of the Parties under this Agreement are cumulative, and either Party may enforce any of its rights or remedies under this Agreement or other rights and remedies available to it at law or in equity.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.7.</b> The section headings of this Agreement are for convenience only and have no interpretive value. Any list of examples following "including" or "e.g.," is illustrative and not exhaustive, unless qualified by terms like "only" or "solely." This Agreement may be executed by facsimile and in counterparts, which together will constitute one and the same agreement. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.8.</b> The rights and obligations of a Party which by their nature must survive termination or expiration of this Agreement in order to achieve its fundamental purposes will survive any termination of this Agreement.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.9.</b> Except for a party’s confidentiality and indemnification obligations, in no event will either party be liable hereunder for any incidental, indirect, special, consequential, punitive or exemplary damages, lost profits, lost sales or anticipated order, or damages for loss of goodwill, even if a party was informed or knew or should have known of the possibility of such damages or loss.
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.10.</b> Except for its respective confidentiality and indemnification obligations, the aggregate liability of either party for all claims relating to the services or connected with this agreement, regardless of the damages theory, will not exceed the fees paid to Tellius under this agreement in the twelve (12) months preceding the date the claim arose. Tellius will not be liable for any damages incurred by customer to the extent arising from any unauthorized access resulting from the actions of customer or any third party other than Tellius’s representatives. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.11.</b> The Services and Software are subject to the trade laws and regulations of the United States and other countries, including the Export Administration Regulations (EAR, 15 CFR Part 730 et seq.) and the sanctions programs administered by the Office of Foreign Assets Control (OFAC, 31 CFR Part 500). Customer will not import, export, re-export, transfer or otherwise use the Software or Services in violation of these laws and regulations, including by engaging in any unauthorized dealing involving (i) a U.S. embargoed country (currently Cuba, Iran, North Korea, Sudan and Syria), (ii) a party included on any restricted person list, such as the OFAC Specially Designated Nationals List, or the Commerce Department’s Denied Persons List or Entity List, or (iii) the design, development, manufacture, or production of nuclear, missile, or chemical or biological weapons. By using the Software and Services, Customer represent and warrant that Customer are not located in any such country or on any such list. Customer will not engage in activity that would cause Tellius  to be violation of these laws and regulations, and Customer will indemnify Tellius  for any fines, penalties or other liabilities incurred by Tellius  for Customer failure to comply with this provision. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.12.</b> No action, regardless of form, arising from this agreement, may be brought by either Party more than two (2) years after delivery of the Software or Servicesthat would be the subject matter of such action. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.13.</b> Neither Party will be liable for any delay or default in its performance of any obligation under the Agreement (other than a payment obligation) caused directly or indirectly by , fire, flood, act of God, acts of government, an act or omission of civil or military authority of a state or nation, strike, lockout or other labor problem, inability to secure, delay in securing or shortage of, labor, materials, supplies, transportation or energy, failures of subcontractors or suppliers, or by war, riot, embargo or civil disturbance, breakdown, or destruction of plant or equipment arising from any cause whatsoever, or any cause or causes beyond such Party’s reasonable control (collectively, <b>“Force Majeure Events”</b>). This provision will in no way impair either Party’s right to terminate this Agreement. 
                        </p>
                    </div>
                    <div className="col s12 m12 l12 mt5 tal">
                        <p>
                            <b>10.14.</b> This Agreement, constitutes the complete and final agreement of the Parties pertaining to the Services, and supersedes the Parties’ prior agreements, understandings and discussions relating to the Services. No modification of this Agreement is binding unless it is in writing and signed by Customer and Tellius . In the event of any conflict or inconsistency between a provision in these Terms and in an Order, the provision in these Terms will govern unless the Order expressly indicates the intent to override the contrary provision in the Terms.
                        </p>
                    </div>
                </div>
            </div>
        );  
    }
}
export default Terms;