import axios from "axios";
import {
    GET_ERRORS,
    SET_COMPANY_SUCCESS,
    CREATE_DEPLOYMENT_SUCCESS,
    DEPLOYMENT_LIST,
    SET_MARKETING_COOKIE
} from "./types";
import _ from "lodash";

// Set company name
export const setCompanyName = companyData => dispatch => {
    axios
        .put("/api/users", companyData)
        .then(res => {
            dispatch(setCompanyNameSuccess(res.data));
            dispatch(createDeployment());
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// create deployment
export const createDeployment = (companyData, history) => dispatch => {
    axios
        .post("/api/users/deployment", companyData)
        .then(res => {
            dispatch(createDeploymentSuccess(res.data));
            history.push("/created");
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
// Set company name successfull
export const setCompanyNameSuccess = success => {
    return {
        type: SET_COMPANY_SUCCESS,
        payload: success
    };
};
// Create deployment successfull
export const createDeploymentSuccess = success => {
    return {
        type: CREATE_DEPLOYMENT_SUCCESS,
        payload: success
    };
};

//update Deployment List
export const updateDeploymentList = deploymentList => {
    return {
        type: DEPLOYMENT_LIST,
        payload: deploymentList.data
    };
};

// delete Deployment
export const deleteDeployment = deploymentData => dispatch => {
    axios
        .delete("/api/users", { data: deploymentData }
        )
        .then(res => {
            dispatch(updateDeploymentList(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

// expire Deployment
export const expireDeployment = deploymentData => dispatch => {
    axios
        .delete("/api/users/expireDeployment", { data: deploymentData })
        .then(res => {
            dispatch(updateDeploymentList(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

// terminate Deployment
export const terminateDeployment = deploymentData => dispatch => {
    axios
        .delete("/api/users/terminateDeployment", { data: deploymentData })
        .then(res => {
            dispatch(updateDeploymentList(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

// get Deployments
export const listDeployment = () => dispatch => {
    axios
        .get("/api/users/listDeployment")
        .then(res => {
            dispatch(updateDeploymentList(res.data));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

// set Cookie data
export const setCookieData = (cookieData) => dispatch => {
    dispatch({
        type: SET_MARKETING_COOKIE,
        payload: cookieData
    });
};

//fetch Deployment List
export const fetchDeploymentList = deploymentList => {
    return listDeployment()
};

// update trial license for the User
export const trialLicenseExtension = rejectionData => dispatch => {
    axios
        .put("/api/users/trialLicenseExtension", rejectionData)
        .then(res => {
            dispatch(fetchDeploymentList());
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: _.get(err, 'response.data')
            })
        );
};