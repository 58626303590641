import {
    SET_COMPANY_SUCCESS,
    CREATE_DEPLOYMENT_SUCCESS,
    DEPLOYMENT_LIST,
    SET_MARKETING_COOKIE
} from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
    companyPayload: {},
    createDeploymentPayload: {},
    deploymentListPayload: [],
    cookiePayload: {}
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_COMPANY_SUCCESS:
            return {
                ...state,
                companyPayload: action.payload
            };
        case CREATE_DEPLOYMENT_SUCCESS:
            return {
                ...state,
                createDeploymentPayload: action.payload
            };
        case DEPLOYMENT_LIST:
            return {
                ...state,
                deploymentListPayload: action.payload
            };
        case SET_MARKETING_COOKIE:
            return {
                ...state,
                cookiePayload: action.payload
            }
        default:
            return state;
    }

}