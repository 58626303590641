import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";

class Login extends Component {
    constructor() {
        super();
        this.state = {
            workEmail: "",
            password: "",
            showPassword: false,
            errors: {}
        };
    }
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            if (this.props.auth.user.role === 'ADMIN') {
                this.props.history.push("/deployments");
            } else {
                this.props.history.push("/dashboard");
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            if (nextProps.auth.user.role === 'ADMIN') {
                this.props.history.push("/deployments");
            } else {
                this.props.history.push("/dashboard");
            }
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        if ((e.target.id == 'workEmail' && e.target.value == 'admin@tellius.com') || this.state.workEmail == 'admin@tellius.com') {
            this.setState({
                showPassword: true,
            });
        } else {
            this.setState({
                showPassword: false,
            });
        }
    };
    onSubmit = e => {
        e.preventDefault();
        if (this.state.workEmail == 'admin@tellius.com' && this.state.password != 'nL2G9s$T&@jqZ_GjX3}=yZS2/5-h8+7W') {
            const errors = {
                password: 'Incorrect password'
            }
            this.setState({
                errors: errors
            });
            return;
        }
        const userData = {
            workEmail: this.state.workEmail,
            password: this.state.password
        };
        this.props.loginUser(userData);
    };
    render() {
        const { errors, showPassword } = this.state;
        return (
            <div className="row">
                <div className="heading row">
                    <div className="col s12 m12 l12">
                        <b>Login</b>
                    </div>
                </div>
                <div className="heading-desc row">
                    <div className="col s12 m12 l12">
                        Don't have an account? <Link to="/signup">Signup</Link>
                    </div>
                </div>
                <form noValidate onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <label className="tellius-label">Work email</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.workEmail}
                                error={errors.workEmail}
                                id="workEmail"
                                type="email"
                                className={classnames("tellius-input", {
                                    invalid: errors.workEmail || errors.emailnotfound
                                })}
                            />
                            <span className="red-text">
                                {errors.workEmail}
                                {errors.emailnotfound}
                            </span>
                        </div>
                    </div>
                    <div className={classnames("row", {
                        show: showPassword,
                        hide: !showPassword
                    })}>
                        <div className="col s12 m12 l12">
                            <label className="tellius-label">Password</label>
                            <input
                                onChange={this.onChange}
                                value={this.state.password}
                                error={errors.password}
                                id="password"
                                type="password"
                                className={classnames("tellius-input", {
                                    invalid: errors.password
                                })}
                            />
                            <span className="red-text">
                                {errors.password}
                            </span>
                        </div>
                    </div>
                    <div className="row valign-wrapper">
                        <div className="col s8 m8 l8">
                        </div>
                        <div className="col s4 m4 l4 right-align">
                            <button
                                type="submit"
                                className="tellius-button btn btn-medium waves-light hoverable"
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);