import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
class Landing extends Component {
    render() {
        return (
            <div>
                <Redirect exact from="/" to="signup" />
                <div style={{ height: "75vh" }} className="container valign-wrapper">
                    <div className="row">
                        <div className="col s12 center-align">
                            <br />
                            <div className="col s6">
                                <Link
                                    to="/register"
                                    style={{
                                        width: "140px",
                                        borderRadius: "3px",
                                        letterSpacing: "1.5px"
                                    }}
                                    className="btn btn-large waves-light hoverable blue accent-3"
                                >
                                    Register
                                </Link>
                            </div>
                            <div className="col s6">
                                <Link
                                    to="/login"
                                    style={{
                                        width: "140px",
                                        borderRadius: "3px",
                                        letterSpacing: "1.5px"
                                    }}
                                    className="btn btn-large btn-flat white black-text"
                                >
                                    Log In
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Landing;