import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirmUser } from "../../actions/authActions";
import telliusLogo from "../../assets/tellius-logo.png";

class Confirmation extends Component {
    constructor() {
        super();
        this.state = {
            token: "",
            errors: {}
        };
    }
    componentDidMount() {
        // if (this.props.auth.isAuthenticated) {
        //     this.props.history.push("/dashboard");
        // }
        const token = this.props.match.params.token;
        this.onSubmit(token);
    }
    componentWillReceiveProps(nextProps) {
        // if (nextProps.auth.isAuthenticated) {
        //     this.props.history.push("/dashboard");
        // }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    onSubmit = token => {
        const confirmationData = {
            token: token
        };
        this.props.confirmUser(confirmationData);
    };
    onContinue = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.props.history.push("/dashboard");
    };
    render() {
        const { errors } = this.state;
        const message = this.props.auth.verificationPayload;
        if (errors.msg) {
            return (
                <div className="valign-wrapper" style={{ "height": "100vh" }}>
                    <div className="row col s12 m12 l12 center">
                        <div className="row imageDiv mb0">
                            <div className="col s12 m12 l12">
                                <img src={telliusLogo} alt="logo" height="50" />
                            </div>
                        </div>
                        <div className="heading row">
                            <div className="col s12 m12 l12">
                                <b>Oops!</b>
                            </div>
                        </div>
                        <div className="heading-desc row">
                            <div className="col s12 m12 l12">
                                {errors.msg}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="valign-wrapper" style={{ "height": "100vh" }}>
                    <div className="row col s12 m12 l12 center">
                        <div className="row imageDiv mb0">
                            <div className="col s12 m12 l12">
                                <img src={telliusLogo} alt="logo" height="50" />
                            </div>
                        </div>
                        <div className="heading row">
                            <div className="col s12 m12 l12">
                                <b>Thank you!</b>
                            </div>
                        </div>
                        <div className="heading-desc row">
                            <div className="col s12 m12 l12">
                                You’ve successfully activated your account.
                        </div>
                            <div className="col s12 m12 l12 mt5">
                                To continue your account creation, click the button below.
                        </div>
                        </div>
                        <div className="row" style={{ marginTop: '50px' }}>
                            <div className="col s12 m12 l12">
                                <button
                                    onClick={this.onContinue}
                                    className="tellius-button btn btn-medium waves-light hoverable"
                                >
                                    Continue
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
Confirmation.propTypes = {
    confirmUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { confirmUser }
)(Confirmation);