export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_REGISTRATION_SUCCESS = "SET_REGISTRATION_SUCCESS";
export const SET_VERIFICATION_SUCCESS = "SET_VERIFICATION_SUCCESS";
export const SET_APPROVAL_SUCCESS = "SET_APPROVAL_SUCCESS";
export const SET_REJECTION_SUCCESS = "SET_REJECTION_SUCCESS";
export const SET_COMPANY_SUCCESS = "SET_COMPANY_SUCCESS";
export const SET_RESEND_SUCCESS = "SET_RESEND_SUCCESS";
export const CREATE_DEPLOYMENT_SUCCESS = "CREATE_DEPLOYMENT_SUCCESS";
export const DEPLOYMENT_LIST = "DEPLOYMENT_LIST";
export const SET_MARKETING_COOKIE = "SET_MARKETING_COOKIE";