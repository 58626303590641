import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { approveUser } from "../../actions/authActions";
import telliusLogo from "../../assets/tellius-logo.png";
import classnames from "classnames";

class Approval extends Component {
    constructor() {
        super();
        this.state = {
            token: "",
            approver: "",
            comments: "",
            success: false,
            errors: {}
        };
    }
    componentDidMount() {
        const token = this.props.match.params.token;
        this.setState({
            token: token
        });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    onSubmit = (e) => {
        e.preventDefault();
        if (!this.state.approver) {
            const errors = {
                approver: 'Approver mandatory'
            }
            this.setState({
                errors: errors
            });
            return;
        }
        if (!this.state.comments) {
            const errors = {
                comments: 'Comments mandatory'
            }
            this.setState({
                errors: errors
            });
            return;
        }
        const approvalData = {
            token: this.state.token,
            approver: this.state.approver,
            comments: this.state.comments,
        };
        this.props.approveUser(approvalData);
        this.setState({
            success: true
        });
    };
    onClose = (e) => {
        if (e) {
            e.preventDefault();
        }
        window.open('', '_parent').close();
    };
    render() {
        const { errors, token, success } = this.state;
        const message = this.props.auth.approvalPayload;
        if (token && !success) {
            return (
                <div className="valign-wrapper" style={{ "height": "100vh" }}>
                    <div className="row col s12 m12 l12 center">
                        <div className="row imageDiv mb0">
                            <div className="col s12 m12 l12">
                                <img src={telliusLogo} alt="logo" height="50" />
                            </div>
                        </div>
                        <div className="row">
                            <form noValidate onSubmit={this.onSubmit}>
                                <div className="row">
                                    <div className="col s12 m12 l12">
                                        <label className="tellius-label">Approved by</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.approver}
                                            error={errors.approver}
                                            id="approver"
                                            type="text"
                                            className={classnames("tellius-input", {
                                                invalid: errors.approver
                                            })}
                                        />
                                        <span className="red-text">
                                            {errors.approver}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 m12 l12">
                                        <label className="tellius-label">Comments</label>
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.comments}
                                            error={errors.comments}
                                            id="comments"
                                            type="text"
                                            className={classnames("tellius-input", {
                                                invalid: errors.comments
                                            })}
                                        />
                                        <span className="red-text">
                                            {errors.comments}
                                        </span>
                                    </div>
                                </div>
                                <div className="row valign-wrapper">
                                    <div className="col s12 m12 l12">
                                        <button
                                            type="submit"
                                            className="btn btn-medium waves-light hoverable"
                                            style={{ backgroundColor: "#4CAF50" }}
                                        >
                                            Approve
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        } else if (errors.msg) {
            return (
                <div className="valign-wrapper" style={{ "height": "100vh" }}>
                    <div className="row col s12 m12 l12 center">
                        <div className="row imageDiv mb0">
                            <div className="col s12 m12 l12">
                                <img src={telliusLogo} alt="logo" height="50" />
                            </div>
                        </div>
                        <div className="heading row">
                            <div className="col s12 m12 l12">
                                <b>Oops!</b>
                            </div>
                        </div>
                        <div className="heading-desc row">
                            <div className="col s12 m12 l12">
                                {errors.msg}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="valign-wrapper" style={{ "height": "100vh" }}>
                    <div className="row col s12 m12 l12 center">
                        <div className="row imageDiv mb0">
                            <div className="col s12 m12 l12">
                                <img src={telliusLogo} alt="logo" height="50" />
                            </div>
                        </div>
                        <div className="heading row">
                            <div className="col s12 m12 l12">
                                <b>Approved!</b>
                            </div>
                        </div>
                        <div className="heading-desc row">
                            <div className="col s12 m12 l12">
                                You’ve successfully approved the account request.
                        </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
Approval.propTypes = {
    approveUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { approveUser }
)(Approval);