import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import telliusLogo from "../../assets/sucess.png";
import { logoutUser } from "../../actions/authActions";

class Created extends Component {
    constructor() {
        super();
        this.state = {
            errors: {}
        };
    }
    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        this.props.logoutUser();
    }
    render() {
        return (
            <div className="valign-wrapper" style={{ "height": "100vh" }}>
                <div className="row col s12 m12 l12 center">
                    <div className="heading row">
                        <div className="col s12 m12 l12">
                            <b>Stay Tuned!</b>
                        </div>
                    </div>
                    <div className="heading-desc row">
                        <div className="col s12 m12 l12">
                        We are creating a Tellius instance for you. This can take a few minutes. You can close this browser window. We will notify you via email once your instance is ready.
                        </div>
                    </div>
                    <div className="row imageDiv">
                        <div className="col s12 m12 l12">
                            <img src={telliusLogo} alt="logo" style={{ "height": '220px' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Created.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { logoutUser }
)(Created);